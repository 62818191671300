"use client"

import { Loader } from "daskapital-ds";
import { styled } from "styled-components";

const StyledGrid = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100dvh;
`;

export default function Loading() {
  // You can add any UI inside Loading, including a Skeleton.
  return <StyledGrid>
    <Loader></Loader>
  </StyledGrid>
}